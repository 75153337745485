import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './assets/logo-final.png';
import citiesData from './assets/cities.json';
import { Box, Container, Typography, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Link, Divider, Grid, Button } from '@mui/material';

const SearchEngine = () => {
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('-1');
  const [selectedCity, setSelectedCity] = useState('not found');
  const [address, setAddress] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [fees, setFees] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [apiSearch, setApiSearch] = useState('');

  const isValidUrl = (url) => {
    if (url === '') {
      return false; 
    }
    
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateCityDetails = (city) => {
    const cityDetails = citiesData.find((cityDetails) => cityDetails.City === city);

    if (cityDetails) {
      setAddress(cityDetails["Addresses and Opening Hours"]);
      setContactPhone(cityDetails["Contact Phone"]);
      setOpeningHours(cityDetails["Opening Hours"]);
      setFees(`${cityDetails["Towing Fee (regular size)"]} / ${cityDetails["Storage Fee (regular size)"]}`);
      setAdditionalInformation(cityDetails["more info"]);
      setApiSearch(cityDetails["API search"]);
    } else {
      console.error('City details not found.');
    }
  };

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          
          // Use Nominatim API for reverse geocoding
          axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`)
            .then(response => {
              console.log(response.data);

              const address = response.data.address;
              const countryShort = address.country_code.toUpperCase();
              const cityName = address.city || address.town || address.village;
              setSelectedCity(cityName);

              const cityDetails = citiesData.find(city => city.City === cityName && city.Country === countryShort);
              if (cityDetails) {
                setSelectedCountry(cityDetails.Country);
                updateCityDetails(cityName);
              } else {
                console.error('City not found for the given location.');
              }
            })
            .catch(error => {
              console.error("Error reverse geocoding location:", error);
            });
        }, error => {
          console.error("Error getting geolocation:", error);
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (selectedCountry === '-1' || selectedCity === 'not found') {
      return;
    }

    const cityDetails = citiesData.find(city => city.City === selectedCity && city.Country === selectedCountry);

    if (cityDetails) {
      updateCityDetails(selectedCity);
    } else {
      console.log('Selected city does not match the selected country.');
    }    

  }, [selectedCountry, selectedCity]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleLocationLinkClick = () => {
    setShowLocationSelector(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%', minHeight: '100vh', overflow: 'auto' }}>
      <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center' }}>
        <img src={logo} alt="Safe City Parking" width="300" />
      </Box>

      <Container sx={{ width: '100%', margin: '0px', padding: '0px' }}>
        <Box sx={{ padding: '10px' }}>
          <section>
            {showLocationSelector && (
              <Box>
                <Typography style={{width:'90%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>Please select a location:</Typography>
                <Box sx={{ mt: 2 }}>
                  <Grid container direction="column"  sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item style={{width:'60%'}}>
                      <Typography >Select a Country</Typography>
                      <Select
                        id="Countries"
                        name="Countries"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        fullWidth
                      >
                        <MenuItem value="-1" disabled>
                          <em>Select a country</em>
                        </MenuItem>   
                         {[...new Set(citiesData.map(city => city.Country))].map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item style={{width:'60%', marginTop: '20px'}}>
                      <Typography >Select a City</Typography>
                      <Select
                        id="Cities"
                        name="Cities"
                        value={selectedCity}
                        onChange={handleCityChange}
                        fullWidth
                        renderValue={(selected) => {
                          if (selected === "not found") {
                            return <em>City</em>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value="not found" disabled>
                          <em>Select a city</em>
                        </MenuItem>
                        {selectedCountry !== '-1' &&
                          citiesData.filter(city => city.Country === selectedCountry).map((city, index) => (
                            <MenuItem key={index} value={city.City}>
                              {city.City}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}

            {!showLocationSelector && (
            <Box id="serviceFound" sx={{ mt: 2 }}>
              <Typography><span style={{ fontWeight: 700}}>Your location: </span><span>{selectedCity}</span></Typography>
              <Link href="#" sx={{  display: 'block' }} onClick={handleLocationLinkClick}>Not your location?</Link>
            </Box>
            )}

            <Box id="searchResults" sx={{ display: 'none', mt: 2 }}>
              <Box id="found" sx={{ display: 'none' }}>
                <Typography id="searchResultText"></Typography>
              </Box>
              <Box id="notfound" sx={{ display: 'none' }}>
                <Typography id="searchResultText1"></Typography>
              </Box>
            </Box>

            <Divider sx={{ my: 2, borderColor: 'primary.main', borderWidth: '1px' }} />

            <Box id="serviceDetails" sx={{ mt: 2 }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Address:</TableCell>
                      <TableCell id="serviceAddress">{address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Contact phone:</TableCell>
                      <TableCell id="servicePhone">{contactPhone}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Opening hours:</TableCell>
                      <TableCell id="serviceHours">{openingHours}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Fees:</TableCell>
                      <TableCell id="serviceFees">{fees}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: 700 }}>Additional information:</TableCell>
                      <TableCell id="serviceInfo">{additionalInformation}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box id="cityEngine" sx={{ backgroundColor: 'rgb(209, 19, 36)', width: '98%', mt: 2, mb: 2, p: 2, fontWeight: 'bold', textAlign: 'center', display: 'none' }}>
                <Link id="cityEngineLink" href="#" sx={{ color: 'white' }} target="_blank" rel="noopener noreferrer">
                  Search your vehicle online by clicking here. (you will be redirected to an external site)
                </Link>
              </Box>
            </Box>
          </section>
        </Box>
            {/* <Box sx={{ mt: 2 }} style={{marginBottom: '20px'}}>
              {isValidUrl(apiSearch) && (
                <iframe
                  id="translated_if"
                  src={apiSearch}
                  width="90%"
                  height="300px"
                  style={{ border: '1px solid gray' }}
                  title="Example"
                ></iframe>
              )}
            </Box> */}
            <Box sx={{ mt: 2 }} style={{marginBottom: '20px'}}>
              {apiSearch && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.href = apiSearch}
                >
                  Go to search vehicle page
                </Button>
              )}
            </Box>
      </Container>

      <Box sx={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center', marginTop: 'auto' }}>
        <Typography sx={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
          Contact us for support or refund of the towing fee:&nbsp;&nbsp;
          <Link href="mailto:office@safecityparking.com" sx={{ color: 'white' }}>office@safecityparking.com</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchEngine;